@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

ul li {
    list-style-type: disc;
}

ol li {
    list-style-type: decimal;
}

@font-face {
    font-family: 'Signika';
    src: url('/fonts/Signika.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

body,
html {
    margin: 0;
    padding: 0;
    @apply bg-main;
    @apply text-primary;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

button:focus {
    outline: none;
}

#staking-modal {
    opacity: 1 !important;
}

// Staking Pool Columns
.sp-col-1 {
    width: 100%;
    @media screen and (min-width: 1024px) {
        width: 18%;
    }
}

.sp-col-2 {
    width: 10%;
}
.sp-col-3 {
    width: 11%;
}
.sp-col-4 {
    width: 24%;
}

.sp-col-5 {
    width: 14%;
}

.sp-col-6 {
    width: 14%;
}

.sp-col-7 {
    width: 10%;
}

// Staking Pool Columns
.asp-col-1 {
    width: 100%;
    @media screen and (min-width: 1024px) {
        width: 22%;
    }
}

.asp-col-2 {
    width: 15%;
}
.asp-col-3 {
    width: 16%;
}
.asp-col-4 {
    width: 24%;
}

.asp-col-5 {
    width: 14%;
}

.asp-col-6 {
    width: 10%;
}

.bounty-gradient-card {
    background: linear-gradient(294.68deg, #ae1e55 -71.35%, #241b31 42.43%);
    border: 1px solid rgba(255, 255, 255, 0.03);
}

.dark-mode::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.toggle-checkbox:checked {
    @apply right-0 border-primary;
    right: 0;
}

.toggle-checkbox:checked + .toggle-label {
    @apply bg-accent;
}
